var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-main',{staticClass:"about"},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('img',{staticStyle:{"max-width":"350px","width":"75%"},attrs:{"src":require("../../assets/img/comprasal_login.png")}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('p',{class:{
            'text-h6': _vm.breakpoint==='xs',
            'text-h6': _vm.breakpoint==='sm',
            'text-h4': _vm.breakpoint==='md',
            'text-h3': _vm.breakpoint==='lg',
            'text-h3': _vm.breakpoint==='lx',
            'text-h3': _vm.breakpoint==='2xl',
            'red--text':!_vm.verify
          }},[_vm._v(" "+_vm._s(_vm.message)+" ")]),(_vm.loading)?_c('v-progress-circular',{staticClass:"d-flex mx-auto",attrs:{"indeterminate":"","color":"blueMinsal"}}):_vm._e(),_c('v-btn',{staticClass:"mt-8",staticStyle:{"text-transform":"none"},attrs:{"color":"blueMinsal","rounded":"","to":{name:'identidad-digital'},"text":"","id":"botomAction","large":""}},[_vm._v(" Regresar al login ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }